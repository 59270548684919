<template>

    <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="Settings" :aria-labelledby="t.Settings">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" :id="t.Settings">{{ t.Settings }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" :aria-label="t.Close"></button>
        </div>
        <div class="offcanvas-body">

            <fieldset class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.voices }}</legend>
                <select class="form-select bg-black fs-6 text-light" v-if="store.voices" v-model="store.voice" :aria-label="t.voices">
                    <option v-for="val in store.voices" :key="val" :value="val.voice"> {{ val.name }} </option>
                </select>
            </fieldset>

            <fieldset class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.fontSize }}</legend>
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeFontSize(-10)" :disabled="store.fontSize === 90">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="fontSize" v-model="store.fontSize" min="90" max="490" step="10" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.fontSize }}% </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeFontSize(10)" :disabled="store.fontSize === 490">&plus;</button>
                    </div>
                </div>
            </fieldset>

            <fieldset v-if="!store.neuro" class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.Volume }}</legend>
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeVolume(-0.5)" :disabled="store.Volume === -5">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="Volume" v-model="store.Volume" min="-5" max="15" step="0.5" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.Volume.toFixed(1) }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeVolume(0.5)" :disabled="store.Volume === 15">&plus;</button>
                    </div>
                </div>
            </fieldset>

            <fieldset v-if="!store.neuro" class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.SpeechTempo }}</legend>
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeSpeechTempo(-0.01)" :disabled="store.SpeechTempo === 0.1">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="SpeechTempo" v-model="store.SpeechTempo" min="0.1" max="2.76" step="0.01" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.SpeechTempo.toFixed(2) }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeSpeechTempo(0.01)" :disabled="store.SpeechTempo === 2.76">&plus;</button>
                    </div>
                </div>
            </fieldset>

            <fieldset v-if="store.neuro" class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.SpeechTempo }}</legend>
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeNeuroTempo(-0.01)" :disabled="store.NeuroTempo === 0.5">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="SpeechTempo" v-model="store.NeuroTempo" min="0.5" max="2" step="0.01" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.NeuroTempo.toFixed(2) }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeNeuroTempo(0.01)" :disabled="store.NeuroTempo === 2">&plus;</button>
                    </div>
                </div>
            </fieldset>


            <fieldset v-if="!store.neuro" class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.Pitch }}</legend>                
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changePitch(-1000)" :disabled="store.Pitch === 25000">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="Pitch" v-model="store.Pitch" min="25000" max="85000" step="1000" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.Pitch }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changePitch(1000)" :disabled="store.SpeechTempo === 85000">&plus;</button>
                    </div>
                </div>
            </fieldset>

            <fieldset v-if="!store.neuro" class="fset mb-4 shadow-sm rounded border border-black">
                <legend class="border border-black rounded">{{ t.Alpha }}</legend>
                <div class="row align-items-center">
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeAlpha(-0.05)" :disabled="store.Alpha === -0.8">&minus;</button>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <input type="range" class="form-range" id="Alpha" v-model="store.Alpha" min="-0.8" max="0.8" step="0.05" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="col-auto rounded text-center bg-black fs-6 text-light"> {{ store.Alpha.toFixed(2) }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button class="setButs fw-bolder" @click="changeAlpha(0.05)" :disabled="store.Alpha === 0.8">&plus;</button>
                    </div>
                </div>
            </fieldset> 

            <button id="Reset" type="button" class="settingsBtn btn rounded-4 w-100 fw-bolder py-2 px-3 my-2" @click="$store.commit('resetSettings')"> {{ t.reSet }} </button>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Settings',
    
    props: [
        't'
    ],

    computed: {
        store() {
            return this.$store.state
        },
        fontSize() {
            return this.$store.state.fontSize
        },
        Volume() {
            return this.$store.state.Volume
        },
        NeuroTempo() {
            return this.$store.state.NeuroTempo
        },
        SpeechTempo() {
            return this.$store.state.SpeechTempo
        },
        Pitch() {
            return this.$store.state.Pitch
        },
        Alpha() {
            return this.$store.state.Alpha
        }
    },

    watch: {
        fontSize: function() {
            this.store.fontSize = parseInt(this.store.fontSize)
            window.saveStorage(['fontSize'])
        },
        Volume: function() {
            this.store.Volume = parseFloat(this.store.Volume)
            window.saveStorage(['Volume'])
        },
        SpeechTempo: function() {
            this.store.SpeechTempo = parseFloat(this.store.SpeechTempo)
            window.saveStorage(['SpeechTempo'])
        },
        NeuroTempo: function () {
            this.store.NeuroTempo = parseFloat(this.store.NeuroTempo)
            window.saveStorage(['NeoroTempo'])
        },
        Pitch: function () {
            this.store.Pitch = parseInt(this.store.Pitch)
            window.saveStorage(['Pitch'])
        },
        Alpha: function () {
            this.store.Alpha = parseFloat(this.store.Alpha)
            window.saveStorage(['Alpha'])
        }

    },

    methods: {
        changeFontSize(amount) {
            this.store.fontSize = parseInt(this.store.fontSize) + amount
            window.saveStorage(['fontSize'])
        },
        changeVolume(amount) {
            this.store.Volume = parseFloat(this.store.Volume) + amount
            window.saveStorage(['Volume'])
        },
        changeSpeechTempo(amount) {
            this.store.SpeechTempo = parseFloat(this.store.SpeechTempo) + amount
            window.saveStorage(['SpeechTempo'])
        },
        changeNeuroTempo(amount) {
            this.store.NeuroTempo = parseFloat(this.store.NeuroTempo) + amount
            window.saveStorage(['NeoroTempo'])
        },
        changePitch(amount) {
            this.store.Pitch = parseInt(this.store.Pitch) + amount
            window.saveStorage(['Pitch'])
        },
        changeAlpha(amount) {
            this.store.Alpha = parseFloat(this.store.Alpha) + amount
            window.saveStorage(['Alpha'])
        },

    }

}
</script>
