import { createApp } from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import App from '@/App'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'

createApp(App)
    .use(store)
    .use(VueAxios, axios)
    .mount('#app')