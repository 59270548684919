<template>
    <textarea 
        v-model="store.inputText"
        ref="box"
        class="form-control rounded-4 p-3 mb-2 mx-0"
        @mousemove="onOpWithBox"
        @mouseup="onOpWithBox"
        @keydown="keyDown" 
        @keyup="keyUp"
        :placeholder="t.enterText"
        :style="{ 'font-size': store.fontSize + '%', 'height': textareaHeight + 'px' }" 
        autofocus>
    </textarea>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    name: 'textBox',

    setup() {
        const selectedText = ref('')
        const box = ref(null) // Create a ref to store the textarea element
        
        const handleSelectionChange = () => {
            const textarea = box.value // Access the textarea element from the ref
            const selectedTextValue = textarea.value.substring(
                textarea.selectionStart,
                textarea.selectionEnd
            )
            selectedText.value = selectedTextValue
        }
        
        onMounted(() => {
            // Initialize selectedText after the component is mounted
            handleSelectionChange()
        })
        
        return {
            selectedText,
            box, // Make sure to expose the ref to the template
            handleSelectionChange
        }
    },

    data() {
        return {
            textareaHeight: window.innerHeight / 4.5 ^ 0,
            curKey: '', 
        }
    },
    
    props: [
        't'
    ],
    
    created() {
        window.insertWord = this.insertWord
    },

    computed: {
        inputText() {
            return this.$store.state.inputText
        },
        caretPos() {
            return this.$store.state.caretPos
        },
        store() {
            return this.$store.state
        }
    },
    
    watch: {
        inputText: function() {
            this.box.value = this.inputText
        },
        caretPos: function() {
            this.store.inputText = this.box.value
            this.getLastWord()
            if (this.store.useWordPane) window.loadWordlist(this.store.lastWord)
        },
        selectedText: function(){
            this.store.selectedText = this.selectedText
        }
    },

    mounted() {

        this.$nextTick(() => {
            if (!this.store.waiting) {
                if (this.store.useWordPane) window.loadWordlist(this.store.lastWord)
            }
        })

    },
    
    methods: {

        onResize() {
            this.textareaHeight = window.innerHeight / 4.5 ^ 0
            if (this.store.useWordPane) window.loadWordlist(this.store.lastWord)
        },

        onOpWithBox() {
            this.handleSelectionChange()
            this.store.caretPos = this.box.selectionStart
            this.getLastWord()
            if (!this.store.waiting) {
                if (this.store.useWordPane) window.loadWordlist(this.store.lastWord)
            }
        },

        getLastWord: function() {
            let caret = this.getCaretPosition(this.box)
            this.store.lastWord = /\S+$/.exec(this.box.value.slice(0, caret.end)) ? /\S+$/.exec(this.box.value.slice(0, caret.end))[0] : ''
            this.store.caretPos = caret.start
        },

        keyDown: function (e) { 
            let textbox = e.target
            this.curKey = e.key
            this.upFirst(textbox)
            // If current key was Enter then sent text to synthesis.
            if (this.curKey === 'Enter') {
                e.preventDefault()
                //              this.sendSpeak(this.text, true)
                //                this.url = null
                return
            }
            this.onOpWithBox()
        },

        keyUp: function (e) {

            let box = e.target,
                caret = this.getCaretPosition(box)

            if (['.', ',', ';', ':', '?', '!'].includes(this.curKey)) {
                box.value = box.value.substring(0, caret.end) + ' ' + box.value.substring(caret.end, box.value.length)

                setTimeout(() => {
                    box.selectionStart = box.selectionEnd = caret.end + 1
                }, 5)

            } 
            this.onOpWithBox()
        },

        upFirst: function (box, insert = false) {
            let caret = this.getCaretPosition(box)

            // Change first letter of sentence's word.
            if ([' ', '.', ',', '?', '!'].includes(this.curKey) || insert) {
                let len = this.store.lastWord.length,
                    preStr = (box.value.length > len) ? box.value.substring(0, caret.end - len).trim() : '',
                    word

                if ((preStr.length < 1 && this.store.lastWord.length > 0) || (['.', '?', '!'].includes(preStr[preStr.length - 1]))) {
                    word = this.alphabet(this.store.lastWord[0]) + this.store.lastWord.slice(1)
                    if (word !== 'undefined') {
                        box.value = box.value.substring(0, caret.end - len) + word + box.value.substring(caret.end, box.value.length)

                        setTimeout(() => {
                            box.selectionStart = box.selectionEnd = caret.end + 1
                        }, 5)

                    }
                }
            }
        },

        insertWord: function (insert) {
            if (0 === insert) { return }

            let caret = this.getCaretPosition(this.box),
                cursorPos = caret.start,
                tmpStr = this.box.value,
                preStr = (this.box.value.length > this.store.lastWord.length) ? this.box.value.substring(0, caret.end - this.store.lastWord.length).trim() : ''

            if (this.store.lastWord.length > 0) {
                caret.start -= this.store.lastWord.length
                if (this.store.lastWord[0] === this.store.lastWord[0].toUpperCase()) {
                    insert = insert.charAt(0).toUpperCase() + insert.slice(1)
                }
                if (this.store.lastWord === this.store.lastWord.toUpperCase()) {
                    insert = insert.toUpperCase()
                }
            }

            if ((preStr.length < 1 && insert.length > 0) || (['.', '?', '!'].includes(preStr[preStr.length - 1]))) {
                insert = this.alphabet(insert[0]) + insert.slice(1)
            }

            this.box.value = tmpStr.substring(0, caret.start) + insert + tmpStr.substring(caret.end, tmpStr.length)

            setTimeout(() => {
                cursorPos += insert.length - this.store.lastWord.length
                this.box.selectionStart = this.box.selectionEnd = cursorPos
                this.store.caretPos = caret.start
                this.getLastWord()
            }, 5)

            this.box.focus()
        },

        alphabet: function (char, toUp = true) {
            var doC = 'abcdefghijklmnopqrsštuvwxyzžõäöü',
                upC = 'ABCDEFGHIJKLMNOPQRSŠTUVWXYZŽÕÄÖÜ',
                all = upC + doC

            if (toUp)
                if (doC.indexOf(char) !== undefined)
                    if (upC[doC.indexOf(char)] !== undefined)
                        return upC[doC.indexOf(char)]
                    else
                        return char

            if (all.indexOf(char) !== -1)
                return true
            else
                return false
        },

        getCaretPosition: function (ctrl) {
            var start, end
            if (ctrl.setSelectionRange) {
                start = ctrl.selectionStart
                end = ctrl.selectionEnd
            } else if (document.selection && document.selection.createRange) {
                var range = document.selection.createRange()
                start = 0 - range.duplicate().moveStart('character', -100000)
                end = start + range.text.length
            }
            return {
                start: start,
                end: end
            }
        },
        
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
