<template>

    <div v-if="start" class="container-fluid min-vh-100 pb-3" style="background: gray">

        <div id="tools">
            <svg height="100px" viewBox="0 0 51.43 22">
                <filter id="inset-shadow">
                    <feOffset dx="2" dy="-2" /><!-- Shadow offset -->
                    <feGaussianBlur stdDeviation="1" result="offset-blur" /><!-- Shadow blur -->
                    <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse" /><!-- Invert drop shadow to make an inset shadow-->
                    <feFlood flood-color="black" flood-opacity=".95" result="color" /><!-- Cut colour inside shadow -->
                    <feComposite operator="in" in="color" in2="inverse" result="shadow" />
                    <feComposite operator="over" in="shadow" in2="SourceGraphic" /><!-- Placing shadow over element -->
                    <feDropShadow dx="1" dy="1" stdDeviation="0.75" flood-opacity="0.9" />
                </filter>
                <g filter="url(#inset-shadow)">
                    <path fill="#c1f0ba" d="M51.43,19.79c0,0-15.87,1.2-19.54-2.1c-2.72-2.45-10.19,2.25-14.9-1.16C14.2,14.51,5.02,19.37,0.4,8.71 C-1.75,3.76,5.54,0,5.54,0h45.89V19.79z" />
                </g>
            </svg>

            <a href="https://www.mella.ee/" role="button" target="_blank" @click="clickSound">
                <img id="logo" :src="getFileUrl('Mella.png')" :alt="t.Author" :title="t.Author" />
            </a>
            
            <a href="#" role="button" class="settings" @click.prevent="clickSound" data-bs-toggle="offcanvas" data-bs-target="#Settings" aria-controls="offcanvasWithBothOptions">
                <i class="bi bi-gear-wide-connected"></i>
            </a>

            <a href="#" role="button" class="flag" @click.prevent="store.curLang = langs[nextLang]; changeLang();" :title="langName[nextLang]" :aria-label="t.language + ': ' + langName[nextLang]">
                <img :src="getFileUrl(langs[nextLang] + '.png')" :alt="langName[nextLang]" />
            </a>

        </div>
        
        <div class="container pt-5">
        
            <div id="main" class="container-fluid p-4 rounded-5">

                <!-- TextArea -->            
                <div class="row">
                    <div class="col">
                        <label for="syntText" class="visually-hidden">{{ t.enterText }}</label>
                        <textBox id="syntText" v-model="store.inputText" :t="t" />
                    </div>
                </div>

                <!-- Wordlist -->
                <div v-if="store.useWordPane" class="row">
                    <div class="col">
                        <usedWords :t="t" />
                    </div>
                </div>

                <!-- Control Buttons -->            
                <div class="row">
                    <div class="col">
                        <div class="btn-group my-2 d-grid gap-3 d-flex justify-content-center" style="height: 60px;" role="group">
                            
                            <button @click="Synthesize" :aria-label="t.Synthesize" class="ctrlbtn btn btn-block h-100 vw-100 rounded-4 fw-bold bg-white">
                                <img :src="getFileUrl('icon-synt.png')" :alt="t.Synthesize" />
                                <span class="label ms-2 fs-5"> {{ t.Synthesize }}</span>
                            </button>

                            <button @click="Pause" :aria-label="t.Pause" class="ctrlbtn btn btn-block h-100 vw-100 rounded-4 fw-bold bg-white">
                                <img v-if="isPlaying" :src="getFileUrl('icon-pause.png')" :alt="t.Pause" />
                                <img v-else :src="getFileUrl('icon-play.png')" :alt="t.Play" />
                                <span v-if="isPlaying" class="label ms-2 fs-5"> {{ t.Pause }}</span>
                                <span v-else class="label ms-2 fs-5"> {{ t.Play }}</span>
                            </button>

                            <button @click="Rewind" :aria-label="t.Rewind" class="ctrlbtn btn btn-block h-100 vw-100 rounded-4 fw-bold bg-white">
                                <img :src="getFileUrl('icon-rewind.png')" :alt="t.Rewind" />
                                <span class="label ms-2 fs-5"> {{ t.Rewind }}</span>
                            </button>

                            <button @click="clickSound();clearOut();" :aria-label="t.Clean" class="ctrlbtn btn btn-block h-100 vw-100 rounded-4 fw-bold bg-white">
                                <img :src="getFileUrl('icon-clean.png')" :alt="t.Clean" />
                                <span class="label ms-2 fs-5"> {{ t.Clean }}</span>
                            </button>

                        </div>
                    </div>
                </div>

                <!-- Display -->                            
                <div class="row mt-2">
                    <div class="col">
                        <div ref="display" class="position-relative d-flex rounded-3 text-bg-dark justify-content-center align-items-center">
                            <canvas ref="tracking" class="w-100 position-absolute opacity-50 z-3" width="1248" height="50" style="height: 50px;" />
                            <span v-if="duration && position" class="position-absolute fs-5 fw-bold z-2">
                                {{ position + ' / ' + duration }}
                            </span>
                            <canvas ref="canvas" class="w-100" width="1248" height="50" style="height: 50px; " />
                        </div>
                    </div>
                </div>

                <!-- AI Panel -->            
                <div class="row mt-2">
                    <div class="col">
                    
                        <fieldset class="fset mt-3 shadow rounded border border-black">
                            <legend class="border border-black rounded">{{ t.AIPanel }}</legend>
                            <div class="btn-group row row-cols-md-4 g-3 d-grid d-md-flex align-items-center" role="group">
                                
                                <div class="col-12">
                                    <button @click="translate" :aria-label="t.AITransButton" class="feat btn btn-block w-100 rounded-4 fw-bold lh-1 bg-white">
                                        {{ t.AITransButton }}
                                    </button>
                                </div>

                                <div class="col-12">
                                    <button @click="correct" :aria-label="t.AICorreButton" class="feat btn btn-block w-100 rounded-4 fw-bold lh-1 bg-white">
                                        {{ t.AICorreButton }}
                                    </button>
                                </div>
                                
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <div v-if="store.debug" aria-live="polite" aria-atomic="true" class="d-flex mt-3 justify-content-center align-items-center w-100">
                <div class="toast show" style="width: 80%;" role="alert" aria-live="assertive" aria-atomic="true">

                    <div class="toast-header">
                        <strong class="me-auto">Debug Information</strong>
                        <small></small>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" :aria-label="t.Close"></button>
                    </div>

                    <div class="toast-body">

                        <div class="row" v-if="store.error">
                            <div class="col-4 fw-bold text-danger">ERROR:</div>
                            <div class="col-8 fw-bold text-danger">{{ store.error }}</div>
                        </div>
                        
                        <div class="row" v-if="sentences.length > 0">
                            <div class="col-4 fw-bold">Sentences:</div>
                            <div class="col-8">
                                <ol>
                                    <li v-for="(sentence, index) in sentences" :key="index">{{ sentence }}</li>
                                </ol>
                            </div>    
                        </div>

                        <div class="row" v-if="store.con_id">
                            <div class="col-4 fw-bold">Connection ID:</div>
                            <div class="col-8">{{ store.con_id }}</div>
                        </div>

                        <div class="row" v-if="store.curLang">
                            <div class="col-4 fw-bold">Current Language:</div>
                            <div class="col-8">{{ store.curLang }}</div>
                        </div>

                        <div class="row" v-if="store.voice">
                            <div class="col-4 fw-bold">Current Voice:</div>
                            <div class="col-8">{{ store.voice }} </div>
                        </div>

                        <div class="row">
                            <div class="col-4 fw-bold">Caret Position:</div>
                            <div class="col-8">{{ store.caretPos }} </div>
                        </div>

                        <div class="row" v-if="store.lastWord">
                            <div class="col-4 fw-bold">Last Word:</div>
                            <div class="col-8">{{ store.lastWord }} </div>
                        </div>

                        <div class="row" v-if="store.queryCounter">
                            <div class="col-4 fw-bold">Query Counter:</div>
                            <div class="col-8">{{ store.queryCounter }} | {{ store.waiting ? "Waiting..." : "Not waiting." }}</div>
                        </div>

                        <div class="row" v-if="store.debugmsg">
                            <div class="col-4 fw-bold">Debug Message:</div>
                            <div class="col-8">{{ store.debugmsg }} </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <settings v-if="start" :t="t" />
 
    <div v-if="overlay" class="overlay">
        <div class="row position-absolute top-50 start-50 translate-middle">
            <div class="col">
                <div class="spinner-border text-success" style="width: 10rem; height: 10rem" role="status"></div>
            </div>
        </div>
        <div class="row position-absolute top-50 start-50 translate-middle">
            <div class="col">
                <span class="process justify-content-center"> {{ currentQuery }} </span>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from "vue"
import ini from "ini"
import qs from "qs"
import language from "@/locales/langs.json"
import textBox from "@/components/textBox"
import usedWords from "@/components/usedWords"
import settings from "@/components/settings"
//import SentenceCaseTextarea from './SentenceCaseTextarea.vue'
//import test from "@/components/waveform";

export default {
    name: "App",
    
    data() {
        return {
            speakText: "",
            sentences: [],
            mp3: null,
            isPlaying: false,
            position: '0:0',
            duration: '0:0',
            overlay: false,
            currentQuery: '',
            start: false,
            
            // Language stuff
            t: [],
            langs: Object.keys(language),
            langCount: Object.keys(language).length,
            nextLang: 0,
            defaultVoice: [],
            langName: [],
            syntCommand: []      
        }
    },
    
    components: {
        textBox,
        usedWords,
        settings,
//        test,
//        SentenceCaseTextarea
    },

    setup() {
        const player = new Audio();
        const display = ref(null);
        const canvas = ref(null);
        const tracking = ref(null);
        let audioContext = null;
        let audioContextCreated = ref(false);
        let analyser = null;

        const initAudioContext = async () => {
            if (audioContext === null) {
                audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const source = audioContext.createMediaElementSource(player);
                analyser = audioContext.createAnalyser(); // Määrake analyser siin
                source.connect(analyser);
                analyser.connect(audioContext.destination);
                analyser.fftSize = 512;
                audioContextCreated.value = true
            }
        }

        const play = () => {
            player.play();
            const canvasCtx = canvas.value.getContext('2d');
            const trackingCtx = tracking.value.getContext('2d');
            drawVisualizer(canvasCtx, trackingCtx);
        };

        const drawVisualizer = (canvasCtx, trackingCtx) => { // Lisage canvasCtx siia
            if (!canvasCtx) return;
            if (!trackingCtx) return;

            const WIDTH = canvas.value.width;
            const HEIGHT = canvas.value.height;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            analyser.getByteFrequencyData(dataArray);
            canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
            trackingCtx.clearRect(0, 0, tracking.value.width, tracking.value.height);

            const barWidth = (WIDTH / bufferLength) * 2.5;
            let x = 0;
            let r, g, b;
            let bars = 118;

            for (let i = 0; i < bars; i++) {
                const barHeight = dataArray[i];

                if (dataArray[i] > 210) {
                    r = 250; g = 0; b = 255;
                } else if (dataArray[i] > 200) {
                    r = 250; g = 255; b = 0;
                } else if (dataArray[i] > 190) {
                    r = 204; g = 255; b = 0;
                } else if (dataArray[i] > 180) {
                    r = 0; g = 219; b = 131;
                } else {
                    r = 0; g = 199; b = 255;
                }

                const position = (player.currentTime / player.duration) * 100
                const trackPos = (position / 100) * tracking.value.width
                
                canvasCtx.fillStyle = `rgb(${r},${g},${b})`;
                canvasCtx.fillRect(x, HEIGHT - barHeight / 5, barWidth, barHeight);

                trackingCtx.fillStyle = `rgb(255,255,255)`;
                trackingCtx.fillRect(0, 0, trackPos, tracking.value.height);
                x += barWidth + 1;
            }

            requestAnimationFrame(() => drawVisualizer(canvasCtx, trackingCtx)); // Muutke drawVisualizer kutsungit
        };

        player.addEventListener('play', () => {
            if (audioContext.state === 'suspended') {
                audioContext.resume().then(() => {
                    drawVisualizer(canvas.value.getContext('2d'), tracking.value.getContext('2d'));
                });
            }
        });

        return {
            player,
            display,
            canvas,
            tracking,
            audioContextCreated,
            initAudioContext,
            play
        };    
    },

    beforeCreate() {
        this.axios.get(process.env.BASE_URL + "config.ini?" + Date.now()).then(res => {
            let config = ini.parse(res.data)
            if (config) {
                this.store.debug = (config.general.debug !== undefined) ? config.general.debug : false
                this.store.curLang = (config.general.curLang !== undefined) ? config.general.curLang : 'en'
                this.store.backend = (config.general.backend !== undefined) ? config.general.backend : process.env.BASE_URL + "php/do_things.php"
                this.store.voice_dir = config.voices.voice_dir

                this.langs.forEach((e, i) => {
                    this.langName[i] = language[e].langName
                    this.defaultVoice[e] = config.voices[`default_${e}`]
                    this.syntCommand[e] = config.synthes[`command_${e}`]
                })
            }
        })
    },
    
    created() {
        window.saveStorage = this.saveStorage
    },

    beforeMount() {

        setTimeout(() => {

            if (typeof Storage !== "undefined") {
                if (localStorage.getItem("con_id")) {
                    this.loadStorage()
                } else {
                    this.store.con_id = this.rndCode(32, 'aA#')
                    this.saveStorage(['con_id'])
                }
            }
            
            this.t = language[this.store.curLang]

            if (this.store.curLang === this.langs[this.langCount-1]) {
                this.nextLang = 0
            } else {
                this.nextLang++
            }

            this.start = true            
            this.voiceList()

        }, 1000)

    },

    mounted() {

        this.player.addEventListener('loadedmetadata', () => {
            // Helifaili pikkus sekundites
            const durationInSeconds = this.player.duration
            const minutes = Math.floor(durationInSeconds / 60)
            const seconds = parseInt(durationInSeconds % 60)

            this.duration = minutes + ':' + seconds
        })

        // Lisage kuulaja, et jälgida mängiva positsiooni muutusi
        this.player.addEventListener('timeupdate', () => {
            // Mängiva positsiooni sekundites
            const currentPositionInSeconds = this.player.currentTime
            const minutes = Math.floor(currentPositionInSeconds / 60)
            const seconds = parseInt(currentPositionInSeconds % 60)

            this.position = minutes + ':' + seconds
        })

        this.player.addEventListener('playing', () => {
            this.isPlaying = true
        })

        this.player.addEventListener('ended', () => {
            this.isPlaying = false
        })

    },

    computed: {
        store() {
            return this.$store.state
        },
        voice() {
            return this.$store.state.voice
        },
        inputText() {
            return this.$store.state.inputText
        },
    },

    watch: {
        voice: function() {
            if (this.store.voice.includes('|')) {
                const parts = this.store.voice.split('|')
                if (parts.length === 2) {
                    this.store.neuro = true // 'neuro'
                    this.store.neurovoice = parts[1]  
                }
            } else {
                this.store.neuro = false
                this.store.neurovoice = null
            }
            this.defaultVoice[this.store.curLang] = this.store.voice
            this.saveStorage(['voice'])
        },
        start: function () {
            //this.voiceList()
        },
        inputText: function() {
            this.splitSentences()
        },
        tracking: function() {
            if (this.tracking !== null) {
                // Add click and touch event listeners to the canvas element
                this.tracking.addEventListener('click', (event) => {
                    if (this.audioContextCreated) {
                        const rect = this.tracking.getBoundingClientRect();
                        const x = event.clientX - rect.left;
                        //const y = event.clientY - rect.top;

                        this.movePosition(x)
                    }
                });

                this.tracking.addEventListener('touchstart', (event) => {
                    const rect = this.tracking.getBoundingClientRect();
                    const touch = event.touches[0];
                    const x = touch.clientX - rect.left;
                    //const y = touch.clientY - rect.top;

                    this.movePosition(x)
                }, { passive: true });

            }
        }
    },

    methods: {
        
        movePosition(x) {
            const clampedTrackPos = Math.min(100, Math.max(0, ((x / this.display.clientWidth) * 100)));
            this.player.currentTime = (clampedTrackPos / 100) * this.player.duration;
        }, 
        
        parseValue(value) {
            if (!isNaN(value)) {
                // Kui value on arv
                if (Number.isInteger(Number(value))) {
                    return parseInt(value)
                } else {
                    return parseFloat(value)
                }
            } else {
                // Kui value pole arv
                return value
            }
        },

        rndCode (length, chars) {
            var mask = ''
            if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz'
            if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            if (chars.indexOf('#') > -1) mask += '0123456789'
            if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:"\'<>?,./|\\'
            var result = ''
            for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)]
            return result
        },

        loadStorage () {
            // Funktsioon võtmete massiivi saamiseks
            var getLocalStorageKeys = function() {
                var keys = []
                for (var i = 0; i < localStorage.length; i++) {
                    keys.push(localStorage.key(i))
                }
                return keys
            }

            // Võtmete massiivi saamine
            var keys = getLocalStorageKeys()
            keys.forEach(key => {
                const storedValue = localStorage.getItem(key)
                if (key in this.store) {
                    this.store[key] = this.parseValue(storedValue)
                } else {
                    this[key] = this.parseValue(storedValue)
                }
            })
        },

        saveStorage(keys) {
            keys.forEach(key => {
                const value = key in this.store ? this.store[key] : this[key]
                if (value !== undefined) {
                    localStorage.setItem(key, value)
                }
            })        
        },

        changeLang() { 
            this.t = language[this.store.curLang]
            
            if (document.title !== this.t.AppName) {
                document.title = this.t.AppName
            }
            document.documentElement.setAttribute("lang", this.store.curLang)
            if (this.store.curLang === this.langs[this.langCount - 1]) {
                this.nextLang = 0
            } else {
                this.nextLang++
            }

            this.saveStorage(['curLang'])
            this.voiceList()
            this.translate()
        },

        getFileUrl(filename) {
            return require("@/assets/" + filename)
        },

        splitSentences() {
            if (this.store.inputText.length > 0) {
                const textWithPeriods = this.store.inputText + '.'
                //let text = textWithPeriods.replace(/(\d)\.(?=\s)/g, '$1###PERIOD###')
                let text = textWithPeriods.replaceAll(/(\d)\.(?=\s)|(\d)\.(?=\d)/g, '$1###PERIOD###')
                
                text = text.replaceAll(/\n/g, ' ') // Replace newlines with spaces
                
                let sentences = text.match(/[^.!?]+[.!?]+/g) // Match complete sentences

                if (sentences) {
                    sentences = sentences.map(sentence => sentence.replaceAll('###PERIOD###', '.').trim())
                    this.sentences = sentences
                    if (this.sentences[this.sentences.length - 1] === '.') {
                        this.sentences.pop()
                    } else {
                        this.sentences[this.sentences.length - 1] = this.sentences[this.sentences.length - 1].slice(0, -1) // Eemalda punkt lõpust
                    }
                } else {
                    this.sentences = []
                }
            }
        },
        
        async OpenAI(system, user) {
            if (user.length > 0) {
                const url = this.store.backend
                
                const params = {
                    func: 'openai',
                    system: system,
                    user: user
                }
                this.overlay = true
                await this.axios.post(url, qs.stringify(params)).then((response) => {
                    if (response.data.openai !== undefined) {
                        this.store.inputText = response.data.openai
                    }
                    if (response.data.error) {
                        this.store.error = response.data.error
                    }
                    this.overlay = false
                }).catch((error) => {
                    this.store.error = error
                    console.log(error)
                })  
            }
        },

        translate () {
            if (this.store.inputText.length > 0) {
                this.currentQuery = this.t.Translating
                this.OpenAI(this.t.AITranslate, this.store.inputText)
            }
        },
        
        correct() {
            if (this.store.inputText.length > 0) {
                this.currentQuery = this.t.Correcting
                this.OpenAI(this.t.AICorrectText, this.store.inputText)
            }
        },
        
        clickSound() {
            const click = new Audio()
            click.src = this.getFileUrl("classic-click.mp3")
            click.load()
            click.play()
        },

        Synthesize() {


            let txt = this.$store.state
            
            if (txt.selectedText) {
                this.speakText = txt.selectedText
            } else if (txt.inputText) {
                this.speakText = txt.inputText
            } else {
                this.speakText = ""
            }

            if (this.store.neuro) {
                this.neuroSpeak(this.speakText)
            } else {
                this.sendSpeak(this.speakText, false)
            }
        },

        Pause() {     
            if (this.audioContextCreated) {
                if (this.player.paused) {
                    this.play()
                } else {
                    this.isPlaying = false
                    this.player.pause()
                }
            }
        },

        Rewind() {
            if (this.audioContextCreated) {
                if (!this.player.paused) {
                    this.isPlaying = false
                    this.player.pause()
                }
                this.player.currentTime = 0
                this.play()
            }
        },

        async sendSpeak(txt) {
            if (txt.length > 0) {
                const url = this.store.backend
                
                this.currentQuery = this.t.Synthesizing
                this.overlay = true

                const params = {
                    func: 'synthes',
                    syntcom: this.syntCommand[this.store.curLang],
                    text: txt,
                    voice: this.store.voice,
                    speed: this.store.SpeechTempo,
                    sample: this.store.Pitch,
                    alpha: this.store.Alpha,
                    volume: this.store.Volume,
                    lang: this.store.curLang
                }
                await this.axios.post(url, qs.stringify(params)).then((response) => {
                    this.mp3 = response.data.url
                    this.store.error = response.data.error
                    this.store.debugmsg = response.data.debugmsg
                    this.overlay = false
                    if (this.mp3) {
                        this.player.pause()
                        this.player.src = this.mp3
                        this.player.load()
                        this.initAudioContext()
                    }
                }).catch((error) => {
                    this.store.error = error
                    console.log(error)
                })
            }
        },

        async neuroSpeak(txt) {
            if (txt.length > 0) {
                const url = this.store.backend
                
                this.currentQuery = this.t.Synthesizing
                this.overlay = true

                const params = {
                    func: 'neurosynthes',
                    text: txt,
                    voice: this.store.neurovoice,
                    speed: this.store.NeuroTempo,
                }
                await this.axios.post(url, qs.stringify(params)).then((response) => {
                    this.mp3 = response.data.url
                    this.store.error = response.data.error
                    this.store.debugmsg = response.data.debugmsg
                    this.overlay = false
                    if (this.mp3) {
                        this.player.pause()
                        this.player.src = this.mp3
                        this.player.load()
                        this.initAudioContext()
                    }
                }).catch((error) => {
                    this.store.error = error
                    console.log(error)
                })
            }
        },

        // Get voice list
        async voiceList() {
            if (this.start) {
                let url = this.store.backend 

                const params = {
                    func: 'getVoices',
                    voice_dir: this.store.voice_dir,
                    language: this.store.curLang
                }
                
                await this.axios.post(url, qs.stringify(params)).then((response) => {
                    this.store.voices = response.data.data.data
                    this.store.error = response.data.error
                    this.store.voice = this.defaultVoice[this.store.curLang]
                }).catch((error) => {
                    this.store.error = 'Cannot load the list of voice(s) - '+error
                    console.log(error)
                })
            }
        },
        
        clearOut() {
            this.store.inputText = ""
            this.store.lastWord = ""
            this.store.selectedText = ""
            
            this.speakText = ""
            this.sentences = []
        },
    
    },

}
</script>

<style lang="scss">

//$panelcolor: rgb(255, 255, 255);
$toolpanelsize: 90;
$panelcolor: #c1f0ba;
$shadow: -2px 2px 2px 2px #111, inset 3px -3px 10px -5px rgba(0, 0, 0, 0.7);

@-webkit-keyframes active {
    from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,0 -6px 4px #fefefe, inset 0 0 10px 0px rgba(0, 0, 250, 0.6);
    } to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
    }
}

@keyframes active {
    from {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,0 -6px 4px #fefefe, inset 0 0 10px 0px rgba(0, 0, 250, 0.6);
    } to {
        box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body {
    min-height: 100vh;
}

.fset {
    padding: 15px;
    background-color: $panelcolor;
    box-shadow: 1px 1px 3px 2px #111, inset 10px -10px 15px -5px rgba(0, 0, 0, 0.7);
}

.fset legend {
    margin: -28px 0;
    font-size: 100%;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 0 5px;
    background-color: white;
}

.ctrlbtn {
    height: 55px !important;
    color: #000000;
    box-shadow: 1px 1px 3px 2px #111, inset 10px -10px 15px -5px rgba(0, 0, 0, 0.7);
}

.btn-group .ctrlbtn img {
    width: 30px;
    margin-top: -5px;
}

.feat,
.settingsBtn,
.autoword {
    height: 50px !important;
    color: #000000;
    box-shadow: 1px 1px 3px 2px #111, inset 10px -10px 15px -5px rgba(0, 0, 0, 0.7);
}


.setButs:hover,
.btn-group button:hover {
    box-shadow: 0px 0px 0px 0px #111, inset 5px -5px 15px -5px rgba(0, 0, 0, 0.7);
}

.overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-color: white;
    z-index: 2000;
}

.process {
    font-size: 300%;
    font-weight: bold;
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

#tools {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    z-index: 1500;
}

#tools img#logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 75px;
    height: auto;
    border-radius: 50%;
    margin: 5px;
}

#tools .flag img {
    position: absolute;
    width: 45px; /* width of container */
    height: auto; /* height of container */
    border-radius: 50%;
    overflow: hidden;
    top: 10px;
    right: 165px;
}

#tools img:hover {
    transform: rotate(6deg);
}

#tools .settings .bi-gear-wide-connected {
    position: absolute;
    font-size: 280%;
    color: rgb(0, 60, 189);
    top: 0;
    right: 100px;
}

#tools .settings .bi-gear-wide-connected:hover {
    /* https://stackoverflow.com/questions/16771225/css3-rotate-animation*/
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        } to {
            transform: rotate(360deg);
        }
    }
}

#main {
    /*    border-radius: 30px;*/
    background: $panelcolor;
    box-shadow: 1px 1px 3px 2px #111, inset 10px -10px 15px -5px rgba(0, 0, 0, 0.7);
}

#syntText {
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 120%;
    font-weight: 600;
    color: #606060;
    overflow: auto;
    resize: none;
    box-shadow: 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1, 0 -6px 4px #fefefe, inset 0 0 3px 0 #cecfd1;
}

.setButs {
    font-size: large;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 1px 1px 3px 2px #111, inset 10px -10px 15px -5px rgba(0, 0, 0, 0.7);
}

.st0 {
    fill: #00ff00;
    stroke: #00a000;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st1 {
    fill: #00ffff;
    stroke: #0083ff;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st2 {
    fill: #f9bc43;
    stroke: #c66300;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st3 {
    fill: #ff0000;
    stroke: #840000;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

@media screen and (max-width: 1000px) {
    .btn-group .ctrlbtn .label {
        display: none;
    }
}

</style>
