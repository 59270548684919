<template>
    <Main />
</template>
<script>
import Main from '@/components/Main.vue'

export default {
    components: {
        Main
    }
}
</script>
