<template>
    <div v-if="autowords" class="btn-group d-flex justify-content-between" role="group">
        <button
            type="button"
            v-for="(aword, i) in autowords" :key="aword.word"
            :class="(i===0) ? 'autoword vw-100 rounded-4 fw-bolder text-truncate py-2 px-3 ms-0 my-2' : 'autoword vw-100 rounded-4 fw-bolder text-truncate py-2 px-3 ms-3 my-2' "
            :title="aword.word + ' - ' + aword.score"
            :aria-label="aword.word"
            v-on:click="autocomplete(aword.word)">
            {{ aword.word }}
        </button>
    </div>
</template>

<script>
//import axios from "axios";
import qs from "qs";

export default {
    name: "usedWords",
    
    data() {
        return {
            autowords: [],
        };
    },
    
    props: [
        't'
    ],
    
    computed: {
        store() {
            return this.$store.state;
        },
        curLang() {
            return this.$store.state.curLang;
        }
    },

    created() {
        window.loadWordlist = this.loadWordlist;
    },

    watch: {
        curLang: function () {
            this.loadWordlist(this.store.lastWord);
        },
    },

    mounted() {
        this.loadWordlist(this.store.lastWord)
    },
    
    methods: {
        
        autocomplete: function (word) {
            if (word.length > 0) window.insertWord(word);
            this.loadWordlist(this.store.lastWord);
        },
        
        loadWordlist(word='') {
            const url = this.store.backend,
                limit = (window.innerWidth < 1200) ? 4 : 6;
                    
            this.store.queryCounter++;
            this.$store.commit('reverse');
                
            const params = {
                func: 'getWordlist',
                word: word,
                lang: this.store.curLang,
                limit: limit
            };
            
            this.axios.post(url, qs.stringify(params)).then((response) => {
                this.autowords = response.data.data;
                this.store.debugmsg = response.data.debugmsg;
                this.$store.commit('reverse');
            }).catch((error) => {
                this.store.error = error;
                console.log(error);
            });
        },

    }
};
</script>
