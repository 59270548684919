import { createStore } from 'vuex'

export default createStore({
    state: {
        debug: false,
        curLang: null,
        debugmsg: null,
        error: null,
        neuro: false,
        mp3: '',
        
        con_id: null,

        caretPos: 0,
        clientWidth: window.innerWidth,
        fontSize: 150,
        useWordPane: false,

        inputText: '',
        selectedText: '',
        lastWord: '',

        voice: null,
        neurovoice: null,
        voices: [],
        Volume: 5.0,
        SpeechTempo: 0.8,
        NeuroTempo: 1,
        Pitch: 48000,
        Alpha: 0.55,

        queryCounter: 0,
        waiting: false,

        voice_dir: null,
        backend: null
    },

    mutations: {
        reverse(state) {
            state.waiting = !state.waiting
        },

        resetSettings(state) {
            state.fontSize = 150
            state.Volume = 5.0
            state.SpeechTempo = 0.8
            state.NeuroTempo = 1
            state.Pitch = 48000
            state.Alpha = 0.55
        }
    },
    actions: {},
    modules: {},
})